<template>
    <div class="px-5 pt-3 pb-5 bg-white rounded-lg shadow-lg">
        <el-form ref="form"
                 :rules="rules"
                 :model="form">
            <div class="flex items-end">
                <div class="flex-1">
                    <div class="grid w-full grid-cols-12 gap-4">
                        <div class="col-span-4">
                            <el-form-item class="mb-0"
                                          prop="name">
                                <label class="text-gray-400">
                                    暱稱
                                    <el-input v-model="form.name"
                                              clearable> </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <div class="col-span-4">
                            <div class="flex items-end">
                                <el-form-item class="mb-0 flex-grow-0 w-[90px]"
                                              prop="countryCode">
                                    <label class="text-gray-400">
                                        手機
                                        <el-select v-model="form.countryCode"
                                                   clearable
                                                   class="w-full">
                                            <el-option v-for="item in ['886']"
                                                       :key="item"
                                                       :value="item"
                                                       :label="item"></el-option>
                                        </el-select>
                                    </label>
                                </el-form-item>
                                <el-form-item class="flex-1 flex-grow mb-0"
                                              prop="mobile">
                                    <el-input v-model.number="form.mobile"
                                              clearable
                                              class="w-full"> </el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="col-span-4">
                            <el-form-item class="mb-0"
                                          prop="status">
                                <label class="text-gray-400">
                                    權限
                                    <el-select v-model="form.statuses"
                                               class="w-full"
                                               clearable>
                                        <el-option v-for="(status, index) in statusOptions"
                                                   :key="index"
                                                   :label="status.label"
                                                   :value="status.value">
                                            {{ status.label }}
                                        </el-option>
                                    </el-select>
                                </label>
                            </el-form-item>
                        </div>
                    </div>
                </div>
                <div class="flex-grow-0 ml-4 min-w-[150px]">
                    <div class="flex">
                        <AddSalesmanDialog class="mr-2" />
                        <btn size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                             customClass="flex-shrink mr-1"
                             @onClick="onSubmit('form')">
                            搜尋
                        </btn>
                        <btn color="border border-black"
                             size="md:text-sm text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px]"
                             customClass="flex-shrink"
                             @onClick="resetData">
                            重置
                        </btn>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
// 日期格式套件
import moment from "moment";
// 判斷是否有選擇值
import { checkHaveSelectData } from "../../../service/anyService";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
// 新增城市推廣人
import AddSalesmanDialog from "./AddSalesmanDialog.vue";
export default {
    components: {
        Btn,
        AddSalesmanDialog,
    },
    data() {
        return {
            form: {
                countryCode: "886",
            },
            rules: {
                mobile: [
                    {
                        validator: this.checkPhoneFirstStringZero,
                        trigger: "blur",
                    },
                ],
                countryCode: [{ message: "國碼為必填", trigger: "change" }],
            },
            // 帳號狀態
            statusOptions: [
                { label: "使用中", value: [1] },
                { label: "未通過", value: [-1] },
                { label: "停權", value: [-2] },
            ],
            // 搜尋條件
            filterData: {},
            // 搜尋年份
            years: [],
        };
    },
    methods: {
        // 判斷手機號碼中 是否第一個字為 0
        checkPhoneFirstStringZero(rule, value, callback) {
            // 判斷沒有輸入手機號碼時不執行檢查
            if (this.$isEmpty(value)) {
                callback();
                return;
            }
            if (value.length > 0 && value[0] == 0) {
                this.$set(this.form, "phone", value.substring(1));
                callback();
            } else {
                callback();
            }
        },
        /**
         * 表單發送
         * @param { type String(字串) } formName 傳送表單名稱
         * @example ref="form"
         */
        async onSubmit(formName) {
            try {
                // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
                await this.$refs[formName].validate();
                this.filterSearch();
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "表單驗證失敗",
                });
            }
        },
        // 搜尋條件
        async filterSearch() {
            // 判斷是否有輸入手機號碼
            if (!this.$isEmpty(this.form.mobile)) {
                // 組合手機號碼
                this.form.phone = this.form.countryCode + this.form.mobile;
            } else {
                // 須清空組合後得手機號碼 否則還是一樣會列入搜尋條件
                this.form.phone = null;
            }
            // 判斷是否有值 有值就帶入搜尋條件
            checkHaveSelectData(
                [this.form.name, this.form.phone, this.form.statuses],
                ["name", "phone", "statuses"],
                this.filterData
            );
            this.$emit("onSearch", this.filterData);
        },
        // 清空表單資料
        resetData() {
            this.form = {
                countryCode: "886",
            };
            this.$refs.form.resetFields();
            this.$emit("onReset", {});
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    @apply w-full;
}
</style>
