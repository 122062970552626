<template>
    <div>
        <SearchForm @onReset="resetData" @onSearch="filterSearch" />
        <div class="relative pt-3 pb-5 mt-10 bg-white rounded-lg shadow-lg">
            <LoadingComponent :isLoading="loading" />
            <div class="overflow-x-auto">
                <table class="myTable">
                    <TableHead
                        ref="tableHeadRefDom"
                        :labels="tableLabels"
                        @changeSort="changeSort"
                    />
                    <tbody>
                        <tr
                            v-for="(item, index) in datas"
                            :key="index"
                            class="text-center transition duration-200"
                        >
                            <td>{{ $subString(item.user.name, 10) }}</td>
                            <td>
                                {{
                                    !$isEmpty(item.user.real_name)
                                        ? $subString(item.user.real_name, 10)
                                        : item.user.real_name
                                }}
                            </td>
                            <td>{{ item.user.phone }}</td>
                            <td>
                                {{ item.user.role === 0 ? "會員" : "服務商" }}
                            </td>
                            <td>
                                {{
                                    item.revenue === undefined
                                        ? 0
                                        : item.revenue | formatCurrency
                                }}
                            </td>
                            <td
                                :class="
                                    item.status === 0
                                        ? 'text-black'
                                        : 'text-orange-600'
                                "
                            >
                                {{ SalesmanPermissions[item.status] }}
                            </td>
                            <td
                                v-permission="['update']"
                                class="cursor-pointer"
                                @click="goToEdit(item.user.id, item.user.role)"
                            >
                                <i class="far fa-edit"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex justify-center mt-10">
                <MyPagination
                    :paginationData="paginationData"
                    :currentPage="currentPage"
                    @onPageSizeChange="pageSizeChange"
                    @onCurrentPageChange="currentPageChange"
                />
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 判斷是否 empty 方法
import { isEmpty } from "@/service/anyService";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 搜尋表單
import SearchForm from "./SearchForm.vue";
// 導入城市推廣人狀態顯示
import { SalesmanPermissions } from "@/config/salesmanConfig";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const router = useRouter();
  const route = useRoute();
  // 取得全域 this 方法 ex: this.$api, this.$message
  const {
    proxy
  } = getCurrentInstance();

  // table head dom
  const tableHeadRefDom = ref(null);
  // 預設搜尋條件
  const defaultFilterOpts = {
    statuses: [1, -1, -2]
  };
  // 搜尋條件值
  const filterOpts = ref(defaultFilterOpts);
  // 整理搜尋條件
  const filterData = computed(() => {
    const filter = {};
    for (const key in filterOpts.value) {
      // 判斷 key 是否有值
      if (!isEmpty(filterOpts.value[key])) {
        filter[key] = filterOpts.value[key];
      }
    }
    const result = {
      ...filter,
      page: currentPage.value,
      limit: paginationData.value.limit
    };
    filter.statuses = defaultFilterOpts.statuses;
    return result;
  });
  // table head 標題
  const tableLabels = ref([{
    label: "暱稱"
  }, {
    label: "真實姓名"
  }, {
    label: "手機"
  }, {
    label: "身份"
  }, {
    label: "傭金收益",
    isSort: true,
    val: "revenue"
  }, {
    label: "權限"
  }, {
    label: "編輯"
  }]);
  const loading = ref(false);
  // 搜尋表單 dom
  const searchFormRefDom = ref(null);
  // 分頁資料
  const paginationData = ref({
    limit: 10,
    total: 0
  });
  // 預設頁面
  const currentPage = ref(1);
  // 列表
  const datas = ref([{
    user: {
      name: ""
    },
    category: {
      name: ""
    }
  }]);

  /**
   * 更換每頁筆數
   * @param { type Number(數字) } val 每頁筆數
   */
  function pageSizeChange(val) {
    // 更新每頁取得幾筆資料參數
    paginationData.value.limit = val;
    // 當前頁面設定為第一頁
    currentPage.value = 1;
    getList();
  }

  /**
   * 分頁切換
   * @param { type Number(數字) } val 下一頁數字
   */
  function currentPageChange(val) {
    // 設定目前分頁為第幾面
    currentPage.value = val;
    getList();
  }

  /**
   * 取得列表資料
   */
  async function getList() {
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.GetApplyCityPlanSalesmanListApi(filterData.value);
      loading.value = false;
      currentPage.value = data.current_page;
      paginationData.value = {
        limit: data.per_page,
        total: data.total
      };
      datas.value = data.data;
    } catch (err) {
      console.log(err);
      loading.value = false;
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    }
  }
  /**
   * 跳轉編輯頁
   */
  function goToEdit(id, role) {
    let routeName = "";
    if (role === 0) {
      routeName = "member_update";
    } else {
      routeName = "provider_update";
    }
    router.push({
      name: routeName,
      params: {
        id
      }
    });
  }
  // 點擊重置按鈕事件
  function resetData() {
    filterOpts.value = defaultFilterOpts;
    currentPage.value = 1;
    tableHeadRefDom.value.reset();
    paginationData.value = {
      limit: 10,
      total: 0
    };
    getList();
  }
  // 點擊搜尋按鈕事件
  function filterSearch(val) {
    filterOpts.value = val;
    currentPage.value = 1;
    if (!Object.prototype.hasOwnProperty.call(val, "statuses")) {
      filterOpts.value.statuses = defaultFilterOpts.statuses;
    }
    getList();
  }
  // 更換排序
  const changeSort = val => {
    filterOpts.value.sort_by = val.sortBy;
    filterOpts.value.sort_type = val.orderBy;
    getList();
  };
  onMounted(async () => {
    await getList();
  });
  return {
    SalesmanPermissions,
    tableHeadRefDom,
    tableLabels,
    loading,
    paginationData,
    currentPage,
    datas,
    pageSizeChange,
    currentPageChange,
    goToEdit,
    resetData,
    filterSearch,
    changeSort
  };
};
__sfc_main.components = Object.assign({
  SearchForm,
  LoadingComponent,
  TableHead,
  MyPagination
}, __sfc_main.components);
export default __sfc_main;
</script>
